@use '@angular/material' as mat;
@use 'sass:color';

// Fonts
@import url('assets/fonts/fonts.css');
@import url('assets/icons/icons.css');

// Define color palettes
$primary-palette: mat.m2-define-palette(mat.$m2-indigo-palette);
$accent-palette: mat.m2-define-palette(mat.$m2-pink-palette);
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Light theme configuration
$light-theme: mat.m2-define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette
  )
));

// Dark theme configuration
$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette
  )
));

// Include the base theme styles for all components (light theme by default)
@include mat.all-component-themes($light-theme);

// Apply dark theme colors only when .theme-dark class is active on the body
.theme-dark {
  @include mat.all-component-colors($dark-theme);
}

// Typography configuration
$fonts: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(24px, 32px, 400, $font-family: "Amiri"),
);


// Light Theme Text
$dark-text: #242e66;
$dark-primary-text: #242e66;
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: color.adjust($light-background, $lightness: -5%);
$light-bg-darker-10: color.adjust($light-background, $lightness: -10%);
$light-bg-darker-20: color.adjust($light-background, $lightness: -20%);
$light-bg-darker-30: color.adjust($light-background, $lightness: -30%);
$light-bg-lighter-5: color.adjust($light-background, $lightness: 5%);
$dark-bg-tooltip: color.adjust(#2c2c2c, $lightness: 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: color.adjust($dark-background, $lightness: 5%);
$dark-bg-lighter-10: color.adjust($dark-background, $lightness: 10%);
$dark-bg-lighter-20: color.adjust($dark-background, $lightness: 20%);
$dark-bg-lighter-30: color.adjust($dark-background, $lightness: 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.elevation-classes();
@include mat.app-background();

// Theme Config

body {
  --primary-color: #242e66;
  --primary-lighter-color: #bdc0d1;
  --primary-darker-color: #161d49;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #242e66,
  lighter: #bdc0d1,
  darker: #161d49,
  200: #242e66, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #3cc455;
  --accent-lighter-color: #c5edcc;
  --accent-darker-color: #27af3b;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #3cc455,
  lighter: #c5edcc,
  darker: #27af3b,
  200: #3cc455, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #d90052;
  --warn-lighter-color: #f4b3cb;
  --warn-darker-color: #ca0038;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #d90052,
  lighter: #f4b3cb,
  darker: #ca0038,
  200: #d90052, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
}

.mat-badge-content {
  font-family: 'Roboto';
}

// Customize Tooltip.
:root {
  @include mat.tooltip-overrides((
    container-color: white,
    supporting-text-color: #161d49,
    supporting-text-size: 13px,
    supporting-text-weight: 400,
  ));
}

.mat-mdc-tooltip {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
